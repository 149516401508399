import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p>{`To use PaymentsDS with the JavaScript SDK you need to have:`}</p>
    <pre><code parentName="pre" {...{}}>{`Node.js 10+
NPM or Yarn
`}</code></pre>
    <p>{`Below, you will find examples of the PaymentsDS use cases:`}</p>
    <h3 {...{
      "id": "receive-money-from-a-mobile-account-to-a-business-account"
    }}>{`Receive money from a mobile account to a business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from "@paymentsds/mpesa"

const client = new Client({
  apiKey: "<REPLACE>", // API Key
  publicKey: "<REPLACE>", // Public Key
  serviceProviderCode: "<REPLACE>", // input_ServiceProviderCode
})

const paymentData = {
  from: "841234567", // input_CustomerMSISDN
  reference: "11114", // input_ThirdPartyReference
  transation: "T12344CC", // input_TransactionReference
  amount: "10", // input_Amount
}

client
  .receive(paymentData)
  .then(r => {
    console.log(r.data)
  })
  .catch(e => {
    console.log(e)
  })
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-mobile-account"
    }}>{`Send money from a business account to a mobile account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from "@paymentsds/mpesa"

const client = new Client({
  apiKey: "<REPLACE>", // API Key
  publicKey: "<REPLACE>", // Public Key
  serviceProviderCode: "<REPLACE>", // input_ServiceProviderCode
})

const paymentData = {
  to: "841234567", // input_CustomerMSISDN
  reference: "11114", // input_ThirdPartyReference
  transation: "T12344CC", // input_TransactionReference
  amount: "10", // input_Amount
}

client
  .send(paymentData)
  .then(r => {
    console.log(r.data)
  })
  .catch(e => {
    console.log(e)
  })
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-another-business-account"
    }}>{`Send money from a business account to a another business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from "@paymentsds/mpesa"

const client = new Client({
  apiKey: "<REPLACE>", // API Key
  publicKey: "<REPLACE>", // Public Key
  serviceProviderCode: "<REPLACE>", // input_ServiceProviderCode
})

const paymentData = {
  to: "979797", // input_ReceiverPartyCode
  reference: "11114", // input_ThirdPartyReference
  transation: "T12344CC", // input_TransactionReference
  amount: "10", // input_Amount
}

client
  .send(paymentData)
  .then(r => {
    console.log(r.data)
  })
  .catch(e => {
    console.log(e)
  })
`}</code></pre>
    <h3 {...{
      "id": "revert-a-transaction"
    }}>{`Revert a transaction`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from '@paymentsds/mpesa'

const client = new Client({
   apiKey: '<REPLACE>',             // API Key
   publicKey: '<REPLACE>',          // Public Key
   serviceProviderCode: '<REPLACE>' // input_ServiceProviderCode,
   initiatorIdentifier: '<REPLACE>' // input_InitiatorIdentifier,
   securityIdentifier: '<REPLACE>'  // input_SecurityCredential
});

const reversionData = {
   reference: '11114',           // input_ThirdPartyReference
   transation: 'T12344CC',       // input_TransactionID
   amount: '10'                  // input_ReversalAmount
};

client.revert(reversionData).then(r => {
   // Handle success scenario
}).catch(e =>{
   // Handle failure scenario
});
`}</code></pre>
    <h3 {...{
      "id": "query-the-status-of-a-transaction"
    }}>{`Query the status of a transaction`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from '@paymentsds/mpesa'

const client = new Client({
   apiKey: '<REPLACE>',             // API Key
   publicKey: '<REPLACE>',          // Public Key
   serviceProviderCode: '<REPLACE>' // input_ServiceProviderCode,
   initiatorIdentifier: '<REPLACE>' // input_InitiatorIdentifier,
   securityIdentifier: '<REPLACE>'  // input_SecurityCredential
});

const reversionData = {
   reference: '11114', // input_ThirdPartyReference
   subject: '5C1400CVRO', // input_QueryReference
};

client.query(reversionData).then(r => {
   // Handle success scenario
}).catch(e => {
   // Handle failure scenario
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      